import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import TaskPage from './pages/TaskPage';
import CreatePage from './pages/CreatePage';
import TaskDetailPage from './pages/TaskDetailPage';
import ProtectedRoute from './components/route/ProtectedRoute';
import MonitoringPage from './pages/MonitoringPage';
import BlogPage from './pages/BlogPage'
import BlogwritePage from './pages/BlogwritePage'
import BlogEditPage from './pages/BlogEditPage'
import NewsPage from './pages/NewsPage'

const RedirectToExternal = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // 리다이렉트 처리 후 컴포넌트 자체는 렌더링되지 않음
};

const App = () => {
  return (
    <Routes>
      {/* '/' 경로에 접근 시 연구실 홈페이지로 리다이렉트 */}
      <Route path="/" element={<RedirectToExternal url="https://sites.google.com/view/keylee" />} />

      {/* 기본 '/' 경로에 접근 시 '/fedops'로 리다이렉트 */}
      {/* <Route path="/" element={<Navigate to="/fedops" />} /> */}


      {/* 내부 라우트 설정 */}
      <Route path="/fedops" element={<MainPage />} />
      <Route path="/fedops/login" element={<LoginPage />} />
      <Route path="/fedops/register" element={<RegisterPage />} />
      <Route path="/fedops/task" element={<ProtectedRoute component={TaskPage} />} />
      <Route
        path="/fedops/task/create"
        element={<ProtectedRoute component={CreatePage} />}
      />
      <Route
        path="/fedops/task/:title/*"
        element={<ProtectedRoute component={TaskDetailPage} />}
      />
      <Route path="/fedops/blog" element={<BlogPage />} />
      <Route path="/fedops/blogwrite" element={<BlogwritePage />} />
      <Route path="/fedops/blog/edit/:id" element={<ProtectedRoute component={BlogEditPage} />} />
      <Route path="/fedops/news" element={<NewsPage />} />
      <Route path="/fedops/newswrite" element={<BlogwritePage />} />
      <Route path="/fedops/news/edit/:id" element={<ProtectedRoute component={BlogEditPage} />} />
    </Routes>
  );
};

export default App;
