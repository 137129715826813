import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import axios from "axios";

import Header from "../components/common/Header";
import ContentWrapper from "../components/common/ContentWrapper";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentUser, setCurrentUser] = useState(null); // 사용자 정보를 저장
  const navigate = useNavigate();

  // Fetch posts and user information from the backend
  useEffect(() => {
    // Fetch posts
    axios.get(`/fedops/api/blog/list`).then((response) => {
      setPosts(response.data.reverse());
    });

    // Fetch current user
    const fetchUserInfo = async () => {
      const response = await axios.get(`/fedops/api/auth/check`);
      setCurrentUser(response.data);
    };
    fetchUserInfo();
  }, []);

  // Handle post deletion
  const handleDelete = async (postId) => {
    await axios.delete(`/fedops/api/blog/posts/${postId}`);
    const updatedPosts = await axios.get(`/fedops/api/blog/list`);
    setPosts(updatedPosts.data);
  };

  // Navigate to blog write page
  const handleWriteClick = () => {
    navigate("/fedops/blogwrite");
  };

  // 사용자 확인 함수
  const isAuthorizedUser = currentUser?.isAdmin;

  return (
    <div>
      <Header />
      <ContentWrapper>
        <div style={{ maxWidth: "1200px", margin: "20px auto", padding: "20px" }}>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>FedOps Blog</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {isAuthorizedUser && (
              <button
                onClick={handleWriteClick}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                글쓰기
              </button>
            )}
          </div>
          {!selectedPost ? (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                  gap: "20px",
                }}
              >
                {posts.map((post) => (
                  <div
                    key={post._id}
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      transition: "transform 0.2s",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                  >
                    {post.image && (
                      <img
                        onClick={() => setSelectedPost(post)}
                        src={post.image}
                        alt={post.title}
                        style={{cursor: "pointer",width: "100%", height: "200px", objectFit: "cover" }}
                      />
                    )}
                    <div style={{ padding: "15px" }}>
                      <h3
                        onClick={() => setSelectedPost(post)}
                        style={{
                          cursor: "pointer",
                          marginBottom: "10px",
                          fontSize: "18px",
                          textAlign: "center",
                        }}
                      >
                        {post.title}
                      </h3>
                      {isAuthorizedUser && (
                        <button
                          onClick={() => handleDelete(post._id)}
                          style={{
                            display: "block",
                            margin: "10px auto",
                            padding: "8px 16px",
                            fontSize: "14px",
                            backgroundColor: "#dc3545",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          삭제
                        </button>
                        
                      )}
                      {isAuthorizedUser && (
                        <button
                          onClick={() => navigate(`/fedops/blog/edit/${post._id}`)}
                          style={{
                            display: "block",
                            margin: "10px auto",
                            padding: "8px 16px",
                            fontSize: "14px",
                            backgroundColor: "#ffc107",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          수정
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <button
                onClick={() => setSelectedPost(null)}
                style={{
                  marginBottom: "20px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Back to Posts
              </button>
              <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // 가로 정렬
                      justifyContent: "center", // 세로 정렬
                      textAlign: "center", // 텍스트 가운데 정렬
                      marginBottom: "20px",
                    }}
                  >
                    <h1 style={{ marginBottom: "20px", fontsize: "25000px" }}>{selectedPost.title}</h1>
                    {selectedPost.image && (
                      <img
                        src={selectedPost.image}
                        alt={selectedPost.title}
                        style={{
                          maxWidth: "80%", // 이미지 크기 조정
                          height: "auto",
                          borderRadius: "8px",
                          marginBottom: "20px",
                        }}
                      />
                    )}
                  </div>
              <ReactMarkdown>{selectedPost.content}</ReactMarkdown>
            </>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

export default BlogPage;
