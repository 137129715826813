import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import Header from '../components/common/Header';
import ContentWrapper from '../components/common/ContentWrapper';

const NewsPage = () => {
  const [posts, setPosts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); // 사용자 정보를 저장
  const [selectedPost, setSelectedPost] = useState(null);
  const navigate = useNavigate();

  // Fetch posts and user information from the backend
  useEffect(() => {
    // Fetch posts
    axios.get(`/fedops/api/news/list`).then((response) => {
      const sortedPosts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPosts(sortedPosts);
    });

    // Fetch current user
    const fetchUserInfo = async () => {
      const response = await axios.get(`/fedops/api/auth/check`);
      setCurrentUser(response.data);
    };
    fetchUserInfo();
  }, []);

  // Handle post deletion
  const handleDelete = async (postId) => {
    await axios.delete(`/fedops/api/news/posts/${postId}`);
    const updatedPosts = await axios.get(`/fedops/api/news/list`);
    const sortedPosts = updatedPosts.data.sort((a, b) => new Date(b.date) - new Date(a.date));
    setPosts(sortedPosts);
  };

  // Navigate to news write page
  const handleWriteClick = () => {
    navigate("/fedops/newswrite");
  };

  // 사용자 확인 함수
  const isAuthorizedUser = currentUser?.isAdmin;


  return (
    <div>
      <Header />
      <ContentWrapper>
        <div style={{ maxWidth: "1200px", margin: "20px auto", padding: "20px" }}>
          <h1 style={{ textAlign: "center", marginBottom: "20px" }}>FedOps News</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {isAuthorizedUser && (
              <button
                onClick={handleWriteClick}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                글쓰기
              </button>
            )}
          </div>
          {!selectedPost ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "20px",
            }}
          >
            {posts.map((post) => (
              <div
                key={post._id}
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s",
                  position: "relative",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                {post.image && (
                  <img
                    onClick={() => setSelectedPost(post)}
                    src={post.image}
                    alt={post.title}
                    style={{ cursor: "pointer", width: "100%", height: "200px", objectFit: "cover" }}
                  />
                )}
                <div style={{ padding: "15px", paddingTop: "40px" }}> {/* Adjust paddingTop to avoid overlap */}
                  <div
                    style={{
                      position: "absolute",
                      top: "15px",
                      left: "15px",
                      backgroundColor: "#000",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      zIndex: 1, // Ensure tag is above title
                    }}
                  >
                    {post.tag}
                  </div>
                  <h3
                    onClick={() => setSelectedPost(post)}
                    style={{
                      margin: "10px 0",
                      fontSize: "18px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {post.title}
                  </h3>
                  <p style={{ margin: "10px 0", fontSize: "14px", color: "#555" }}>
                    {post.author}
                  </p>
                  <p style={{ margin: "10px 0", fontSize: "14px", color: "#555" }}>
                    {new Date(post.date).toLocaleDateString()}
                  </p>
                  {isAuthorizedUser && (
                    <button
                      onClick={() => handleDelete(post._id)}
                      style={{
                        display: "block",
                        margin: "10px auto",
                        padding: "8px 16px",
                        fontSize: "14px",
                        backgroundColor: "#dc3545",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      삭제
                    </button>
                  )}
                  {isAuthorizedUser && (
                    <button
                      onClick={() => navigate(`/fedops/news/edit/${post._id}`)}
                      style={{
                        display: "block",
                        margin: "10px auto",
                        padding: "8px 16px",
                        fontSize: "14px",
                        backgroundColor: "#ffc107",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      수정
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          ):(
            <>
            <button
                onClick={() => setSelectedPost(null)}
                style={{
                  marginBottom: "20px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Back to Posts
              </button>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {selectedPost.image && (
                  <img
                    src={selectedPost.image}
                    alt={selectedPost.title}
                    style={{
                      maxWidth: "80%", // 이미지 크기 조정
                      height: "auto",
                      borderRadius: "8px",
                      marginBottom: "20px",
                    }}
                  />
                )}
              </div>
              {selectedPost.tag === "VIDEO" && (
                <div style={{ margin: "10px 0" }}>
                  <iframe
                    width="1140"
                    height="630"
                    src={selectedPost.link.replace("watch?v=", "embed/")}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              <ReactMarkdown>{selectedPost.content}</ReactMarkdown>
              {selectedPost.tag === "PAPER" && (
                <div style={{ margin: "10px 0" }}>
                  <a
                    href={selectedPost.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      fontSize: "14px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      borderRadius: "4px",
                      textDecoration: "none",
                    }}
                  >
                    View Publication
                  </a>
                </div>
                  )}
            </>
          )}
        </div>
      </ContentWrapper>
    </div>
  );
};

export default NewsPage;
